<script>
import { isSupportButtonDisabled } from 'tripod-styles/src/libraries/coreApp/support_customer_service';
import { onMount } from "svelte";

let isLoaded = false;
let optionsToShow = window?.highlevelchat?.items ?? ["whatsapp", "zoom", "email", /*"phone"*/, "chat"]

if((optionsToShow ?? [])?.length <= 0) {
  optionsToShow = ["whatsapp", "zoom", "email", "phone", "chat"];
}

const today = new Date();
const isButtonDisabled = isSupportButtonDisabled(today);

if(isButtonDisabled){
  optionsToShow = ["email"];
}

setInterval(() => {
  if(isButtonDisabled){
    optionsToShow = ["email"];
  }
}, 30000);

let imagesList = {
  whatsapp: "https://bucket.3pod.io/adm/gallery/v-whatsapp-l3ih.svg",
  zoom: "https://bucket.3pod.io/adm/gallery/v-video-yck.svg",
  email: "https://bucket.3pod.io/adm/gallery/frame420-9aip.svg",
  phone: "https://bucket.3pod.io/adm/gallery/v-call-calling-mrn.svg",
  chat: "https://bucket.3pod.io/adm/gallery/v-messages-lk5r.svg"
}

function handleClick(e){
  let element = e.target;
  let container = e.currentTarget;
  let tagName = element?.tagName?.toLowerCase();
  nodebubbleWhatsapp = document.querySelector("[chat-icon='messageWhatsApp']");
  nodebubbleMsg = document.querySelector("[chat-icon='messageChatCircle']");

  if(tagName !== "hightlevel-trigger-chat") {
    let closestFigure = element.closest("figure");
    if(!closestFigure) return;
    
    if(closestFigure?.classList?.contains("whatsapp-chat-container") && nodebubbleWhatsapp) {
      /*let whatsappTrigger = nodebubbleWhatsapp?.shadowRoot?.querySelector("button[aria-label*='open'], button[aria-label*='close']");
      if(!whatsappTrigger) return;*/
      const whatsappTrigger = document.createElement('a');
      whatsappTrigger.href = "https://api.whatsapp.com/send?phone=+16506996504&text=Quiero%20saber%20más!";
      whatsappTrigger.target = "_blank";
      whatsappTrigger.click();
    }
    
    if(closestFigure?.classList?.contains("zoom-chat-container")) {
      const zoomtoLink = document.createElement('a');
      zoomtoLink.href = "https://zoom.us/j/5235280903?pwd=dUtyMXJ4S1d3RnpOWDNZZDlLQUxpQT09";
      zoomtoLink.target = "_blank";
      zoomtoLink.click();
    }
    if(closestFigure?.classList?.contains("email-chat-container")) {
      const mailtoLink = document.createElement('a');
      mailtoLink.href = "https://api.leadconnectorhq.com/widget/form/Kcxzlk4wiVr40xiX73yT";
      mailtoLink.target = "_blank";
      
      mailtoLink.click();
    }
    if (closestFigure?.classList?.contains("phone-chat-container")) {
      const phoneToLink = document.createElement('a');
      phoneToLink.href = "tel:+16506996504";
      phoneToLink.target = "_blank";
      phoneToLink.click();
    }
    if(closestFigure?.classList?.contains("chat-chat-container") && nodebubbleMsg) {
      let msgTrigger = nodebubbleMsg?.shadowRoot?.querySelector("button[aria-label*='open'], button[aria-label*='close']");
      if(!msgTrigger) return;
      msgTrigger.click();
    }
    if(closestFigure?.classList?.contains("trigger-chat-wrapper")) {
      container.classList.toggle("active");
    }
  }
}

function translateValue(optionsToShow){
  let lengthOptions = optionsToShow?.length ?? 5;

  return `${(lengthOptions - 3) * -48}px`;
}

let nodebubbleWhatsapp = null;
let nodebubbleMsg = null;

onMount(() => {
  let counterbuble = 0;
  
  function load_bubble(){
    if(nodebubbleWhatsapp === null || nodebubbleMsg === null){
      return;
    }
    
    nodebubbleWhatsapp = document.querySelector("[chat-icon='messageWhatsApp']");
    nodebubbleMsg = document.querySelector("[chat-icon='messageChatCircle']");
    nodebubbleWhatsapp.classList.add("active-chat")
    nodebubbleMsg.classList.add("active-chat")

    if(nodebubbleWhatsapp.shadowRoot === null){
      return;
    }

    if(nodebubbleMsg.shadowRoot === null){
      return;
    }

    let chats = [nodebubbleWhatsapp, nodebubbleMsg];
    let loaded_chats = 0;
    chats.forEach((chat) => {
      if(chat) {
        let style = document.createElement( 'style' )
        style.innerHTML = `
          button {
            opacity: 0;
            pointer-events: none;
            position: absolute;
          }
          .lc_text-widget.lc_text-widget--active {
            transform: translate(-9%, 50%) !important;
            bottom: 50% !important;
            right: 45px !important;
          }
          .lc_text-widget{
            padding-bottom:0;
          }
            @media only screen and (max-width:900px){
              .lc_text-widget.lc_text-widget--active{
                transform: translate(0%, 50%) !important;
              }
            } 
        `;
        let shadowRoot = chat?.shadowRoot ?? null;
        
        if(shadowRoot !== null){
          shadowRoot.appendChild(style);
          loaded_chats++;
        }
        
      }
    });

    if(loaded_chats >= 2){
      isLoaded = true;
    }
  }
  
  let interval = setInterval(() => {
    nodebubbleWhatsapp = document.querySelector("[chat-icon='messageWhatsApp']");
    nodebubbleMsg = document.querySelector("[chat-icon='messageChatCircle']");
    
    if(nodebubbleWhatsapp !== null && nodebubbleMsg !== null){
      clearInterval(interval);
      setTimeout(function(){
        load_bubble();
      }, 900)
    }
    if(counterbuble >= 30000){
      clearInterval(interval);
      load_bubble()
    }
    counterbuble += 100;
  }, 50);
});

</script>

<svelte:head>
  {#if window?.location?.host?.search("localhost:5000") >= 0}
    <script 
    src="https://widgets.leadconnectorhq.com/loader.js"  
    data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js" 
   data-widget-id="66db7c2ed74329f809ae9c2e"  > 
   </script>
  
  <script 
    src="https://widgets.leadconnectorhq.com/loader.js"  
    data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js" 
   data-widget-id="6703e9bef3e482822c2bcc0d"  > 
   </script>
  {/if}
</svelte:head>

{#if window?.location?.host?.search("localhost:5000") >= 0}
  <div 
    data-chat-widget 
    data-widget-id="66db7c2ed74329f809ae9c2e" 
    data-location-id="N4l4qgGo1QiQWjiwsiFz"  > 
  </div> 
  <div 
    data-chat-widget 
    data-widget-id="6703e9bef3e482822c2bcc0d" 
    data-location-id="N4l4qgGo1QiQWjiwsiFz"  > 
  </div> 
{/if}
<!-- HIGHLEVEL -->

{#if isLoaded}
  <div class="container-highlevel-chat">
    <hightlevel-trigger-chat class="" aria-hidden on:click={(e) => handleClick(e)} style="--translateValue: {translateValue(optionsToShow)}">
      <!-- options -->
      <div class="highlevel-chat-options">
        {#each optionsToShow as option}
          <figure class="{option}-chat-container">
            <img src="{imagesList[option]}" draggable="false" alt="3pod_icon_chat_{option}">
          </figure>
        {/each}
      </div>
      <!-- trigger -->
      <figure class="trigger-chat-wrapper">
          <img src="https://bucket.3pod.io/adm/gallery/t-soporte1-panz.svg" draggable="false" alt="3pod_icon_chat">
      </figure>
    </hightlevel-trigger-chat>
  </div>
{/if}

<style>
  :global(chat-widget){
    opacity: 0;
  }
  :global(chat-widget.active-chat){
    opacity: 1;
  }
  .container-highlevel-chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: fit-content;
  }
  hightlevel-trigger-chat{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      gap: 5px;
      width: fit-content;
      height: auto;
      position: relative;
      user-select: none;
  }

  hightlevel-trigger-chat figure {
    margin: 0;
  }
  hightlevel-trigger-chat figure img {
    user-select: none; 
    object-fit: cover;
  }
  hightlevel-trigger-chat figure.trigger-chat-wrapper img,
  hightlevel-trigger-chat figure.zoom-chat-container img{
    width: 24px;
    height: 24px;
  }
  .highlevel-chat-options {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;

    opacity: 0;
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: -147px;
    transform: translateY(147px);

    transition: transform 0.4s ease-out, opacity 0.2s ease-out;
  }
  hightlevel-trigger-chat:global(.active .highlevel-chat-options) {
    opacity: 1;
    pointer-events: all;
    user-select: all;
    transform: translateY(var(--translateValue));

    transition: transform 0.4s ease-out, opacity 0.4s ease-out;
  }
  hightlevel-trigger-chat figure {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background-color: #2962FF;
    border-radius: 50%;
    color: #FFFFFF;
  }
  hightlevel-trigger-chat figure.whatsapp-chat-container {
    background-color: #45D354;;
  }
  @media only screen and (max-width:600px){
    .container-highlevel-chat {
      right: 0px;
    }
    hightlevel-trigger-chat figure{
      border-radius: 40px 0 0 40px;
      width: 40px;
      height: 40px;
    }
    .container-highlevel-chat{
      bottom: 70px;
    }
  }
</style>